.Card {
    width: 300px;
    height: 480px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 1px 10px 1px black ;
    grid-column: 1;
    grid-row: 1;
}
.upper-container {
    height: 100px;
    background: linear-gradient(to left, #0e6bf7, #8f1fd9)
}
.image-container img {
     background: white;
     width: 100px;
     height: 100px;
     border-radius: 50%;
     padding: 5px;
     transform: translate(95px,45px);

 }

img {
    border: 2px solid #8e00b9;
}
a img{
    border: none;
    padding: 10px;
}

.lower-container{
margin-top: 60px;
height: 300px;
background: white;
text-align: center;
}
.lower-container h3 , h4 , p {
margin: 0;
padding: 0;
}
.lower-container h3 {
         color: #2f2d2e ;
         font-weight: 600;
         font-size: 1.5em;
 }
.lower-container h4 {
         color: #8e00b9;
         font-weight: 700;
         font-size: 0.9rem;
 }
.lower-container p {
         margin: 1rem;
         font-weight: 300;
         font-family: 'Titillium Web', sans-serif;
         color: #2f2d2e;
         font-size: 0.9rem;
         text-align: center;
 }

button {
    margin: 1rem;
    color: #2f2d2e;
    font: bold;
    background-color: white;
    border: 2px solid #8e00b9;
    padding: 0.6rem;
}
h1{
    text-align: center;
}
small {
    text-align: center;
}